<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <return-parts-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="index"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  <a @click.prevent="edit(entity.creditReturnMainId, $event)"
                    :href="generateUrl(entity.creditReturnMainId)">{{ entity.returnNoRef }}</a>
                </td>
                <td>{{ $filters.formatDateTimezone(entity.returnDate, $userInfo.locale) }}</td>
                <td>{{ entity.vendorName }}</td>
                <td>{{ entity.purchaseOrderNoRef }}</td>
                <td>{{ entity.quoteNoRef }}</td>
                <!-- <td></td> -->
                <td class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip"
                    @click.prevent="edit(entity.creditReturnMainId, $event)"
                    :href="generateUrl(entity.creditReturnMainId)"
                    data-tooltip="Click to edit">
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import ReturnPartsListSideMenu from './ReturnPartsListSideMenu'
import ReturnPartsService from './ReturnPartsService'
import { AppHeaderButtonTypes } from '@/enums'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import StoreMixin from './storeMixin'
import ReturnPartsValidation from './ReturnPartsValidation'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import StoreUtil from '@/store/utils'
import { KeyValuePairModel } from '@/classes/viewmodels'
// import _transform from 'lodash/transform'

export default {
  name: 'ReturnPartsListView',
  components: {
    AppDetailHeader,
    [ReturnPartsListSideMenu.name]: ReturnPartsListSideMenu,
    BulmaTable
  },
  mixins: [DateTimeFiltersMixin, StoreMixin, ReturnPartsValidation],
  data() {
    return {
      entitySummaries: {},
      totalRows: 0,
      filter: {
        searchValue: '',
        dateFrom: '',
        dateTo: '',
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  created() {
    this.getData()
  },
  mounted() {  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    generateUrl(id) {
      return `returnparts/${id}`
    },
    edit(id, event) {
      // this.setReadOnlyView(false)
      this.$router.push({
        name: 'ReturnPartsDetail',
        params: { creditReturnMainId: id }
      })
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      // if (this.$v.filter.$error) {
      //   this.$notification.openNotificationWithType('danger', 'Return Parts', 'Filter validation errors.')
      //   return false
      // }
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    resetList() {
      this.filter.searchValue = ''
      this.filter.dateFrom = ''
      this.filter.dateTo = ''
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getData()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    async getData() {
      // this.$showSpinner()
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await ReturnPartsService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
      // this.$hideSpinner()
    },
    // print() {
    //   const params = Object.assign(
    //     {
    //       reportname: this.$route.meta.report,
    //       companyId: this.$userInfo.companyId,
    //       timezoneoffset: this.$filters.getSiteTimezoneOffset()
    //     },
    //     this.filter
    //   )
    //   params.pageIndex = 1
    //   params.pageSize = this.totalRows
    //   const paramsFlat = Object.keys(params)
    //     .map(e => `${e.toLowerCase()}=${params[e]}`)
    //     .join('&')
    //   const url = `${this.$route.meta.reportUrl}?${paramsFlat}`
    //   window.open(url, '_blank')
    // },
    print1() {
      let dateFrom = this.filter.dateFrom
      let dateTo = this.filter.dateTo
      let params = Object.assign(
        {
          CompanyID: this.$userInfo.companyId,
          SearchValue: this.filter.searchValue,
          StartDate: dateFrom,
          EndDate: dateTo,
          SortColumn: this.filter.sortColumn,
          SortDirection: this.filter.sortOrder,
          PageIndex: this.filter.pageIndex,
          PageSize: this.totalRows,
          timezoneoffset: this.$filters.getSiteTimezoneOffset()
        }
      )

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Return Parts List'
      emailer.reportName = 'Return_Part_List'

      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    convertToKeyValuePairs(parameters) {
      const keyValuePairs = Object.keys(parameters).map(function(key) {
        return new KeyValuePairModel(key, parameters[key])
      })
      return keyValuePairs
    },
    async addNew() {
      // this.setReadOnlyView(false)
      this.addStoreItem()
    }
  }
}
</script>
