import { minLength, required } from 'vuelidate/lib/validators'
import { abnValidator, abnMinLength } from '@/components/validators'
import store from '@/store'

const countryCode = () => {
  const company = store.getters['company/company']
  return company.info.countryCode
}

const shouldValidateAbn = () => {
  if (countryCode() === 'AU') {
    return true
  } else {
    return false
  }
}

export default {
  provide: function () {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['entitySummaries.vendorAbn', 'entitySummaries.returnDate'],
    entitySummaries: {
      vendorAbn: {
        required,
        minLength: minLength(abnMinLength(countryCode())),
        validAbn: (value) => {
          if (shouldValidateAbn()) {
            return abnValidator(value)
          } else {
            return true
          }
        }
      },
      returnDate: {
        required
      }
    }
  }
}
